import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

export const fetchReviews = (data) => {
  return {
    type: actionTypes.FETCH_REVIEWS,
    reviews: data,
  };
};

export const fetchRatings = (data) => {
  return {
    type: actionTypes.FETCH_RATINGS,
    ratings: data,
  };
};

export const fetchRev = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');

    axios({
      method: 'GET',
      url: `/api/v1/user/review/count`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        dispatch(fetchReviews(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchReviews(null));
      });

    axios({
      method: 'GET',
      url: `/api/v1/user/review/ratings`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        dispatch(fetchRatings(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchRatings(null));
      });
  };
};

import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';
import { roles } from '../utility';

export const fetchUserSettings = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    axios({
      method: 'GET',
      url: `api/v1/user/getSettings`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        dispatch(updateUserSettings(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateUserSettings({}));
      });
  };
};

export const fetchUserData = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    /**
     * GET user hashed ID
     */
    axios({
      method: 'GET',
      url: `api/v1/user/getHashId`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        dispatch(fetchUserId(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserId(null));
      });

    /**
     * GET user domain white list
     */
    axios({
      method: 'GET',
      url: `api/v1/user/getDomainWhiteList`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        let arrWhiteList = null;
        if (typeof response.data === 'object') {
          arrWhiteList = [];
        } else {
          arrWhiteList = response.data;
        }
        dispatch(fetchWhitelist(arrWhiteList));
      })
      .catch((error) => {
        dispatch(fetchWhitelist([]));
      });

    /**
     * GET user brand
     */
    axios({
      method: 'GET',
      url: `api/v1/user/getBrand`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        dispatch(fetchBrand(response.data));
      })
      .catch(() => {
        dispatch(fetchBrand(''));
      });

    /**
     * GET user role
     */
    axios({
      method: 'GET',
      url: `api/v1/user/getUserPlan`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        dispatch(fetchUserRole(response.data));
      })
      .catch((error) => {
        dispatch(fetchUserRole(roles.free));
      });
  };
};

const fetchUserId = (userId) => {
  return {
    type: actionTypes.FETCH_USERID,
    userId,
  };
};

const fetchWhitelist = (list) => {
  return {
    type: actionTypes.FETCH_WHITELIST,
    whiteList: list.length === 0 ? [] : list.split(','),
  };
};

const fetchBrand = (brand) => {
  return {
    type: actionTypes.FETCH_BRAND,
    brand,
  };
};

const fetchUserRole = (userRole) => {
  return {
    type: actionTypes.FETCH_ROLE,
    userRole,
  };
};

const updateUserSettings = (userSettings) => {
  return {
    type: actionTypes.FETCH_USER_SETTINGS,
    userSettings,
  };
};

export const REG_START = 'REG_START';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAIL = 'REG_FAIL';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_RATINGS = 'FETCH_RATINGS';

export const FETCH_WHITELIST = 'FETCH_WHITELIST';
export const FETCH_USERID = 'FETCH_USERID';
export const FETCH_BRAND = 'FETCH_BRAND';
export const FETCH_ROLE = 'FETCH_ROLE';

export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS';

export const PAYMENT_SUB_ID = 'PAYMENT_SUB_ID';
export const SET_PAYMENT_SUB_ID = 'SET_PAYMENT_SUB_ID';

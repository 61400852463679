import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

export const regStart = () => {
  return {
    type: actionTypes.REG_START,
  };
};

export const regSuccess = () => {
  return {
    type: actionTypes.REG_SUCCESS,
  };
};

export const regFail = (error) => {
  return {
    type: actionTypes.REG_FAIL,
    regError: error,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
  };
};

export const authFail = (error) => {
  console.log(error.message);
  return {
    type: actionTypes.AUTH_FAIL,
    error:
      error.message === 'Network Error'
        ? 'Network Error'
        : error.response.data.error_description,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('id');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const reg = (data) => {
  return (dispatch) => {
    dispatch(regStart());
    axios
      .post('/register', data)
      .then(() => {
        const dataObj = JSON.parse(data);
        dispatch(regSuccess());
        dispatch(auth(dataObj.email, dataObj.password));
        // this.setState({theSameData: null});
        // return console.log(response);
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          dispatch(regFail(error.message));
        } else if (error.response.status === 409) {
          dispatch(regFail(error.response.data));
        }
      });
  };
};

export const auth = (username, password) => {
  return (dispatch) => {
    const reqData = {
      client_id: '6_2e8nbjnyuxs0s80oksc4swkcog88wcswscgo400cko4kssoo8o',
      client_secret: '4nheh1inof40c4gg0w0gs08g48kc4sgk4ggs4ok0cwoosc40c0',
      grant_type: 'password',
      username: username,
      password: password,
    };

    let urlData = Object.keys(reqData)
      .map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(reqData[key]);
      })
      .join('&');

    const authData = {
      method: 'POST',
      url: '/oauth/v2/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'cache-control': 'no-cache',
      },
      data: urlData,
    };

    dispatch(authStart());
    axios(authData)
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + response.data.expires_in * 1000
        );
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('expirationDate', expirationDate);
        dispatch(authSuccess(response.data.access_token));
        dispatch(checkAuthTimeout(response.data.expires_in));
        // dispatch(login(response.data.access_token));
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate < new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

// Dodatkowa funkcja do logowania aby pobrać ID
// export const login = (token) => {
//     if(!!token) {
//       axios({
//         method: 'POST',
//         url: '/login',
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//           "cache-control": "no-cache"
//         },
//         data: {
//           'accesstoken': token
//         }
//       })
//       .then((response) => localStorage.setItem('id', response.data))
//       .catch(error => console.log(error));
//     }
// }

import React, { Component } from 'react';
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import './App.scss';

import * as actions from './store/actions/index';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading,
});

// PagesLogin
const Login = Loadable({
  loader: () => import('./containers/Login/Login'),
  loading,
});

const PasswordRecovery = Loadable({
  loader: () => import('./containers/PasswordRecovery/PasswordRecovery'),
  loading,
});

const Register = Loadable({
  loader: () => import('./containers/Register/Register'),
  loading,
});

const Page404 = Loadable({
  loader: () => import('./containers/Page404/Page404'),
  loading,
});

const Page500 = Loadable({
  loader: () => import('./containers/Page500/Page500'),
  loading,
});

class App extends Component {
  componentDidMount() {
    if (!this.props.location.hash.includes('appsumo_uuid')) {
      this.props.onTryAutoSignUp();
    }
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (location.hash !== '#/login' && location.hash !== '#/register') {
        window.ga('send', 'pageview', window.location.href);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    let routes = (
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route
          exact
          path="/password-recovery"
          name="Password Recovery"
          component={PasswordRecovery}
        ></Route>
        <Route
          exact
          path="/register"
          name="Register Page"
          component={Register}
        />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Redirect to="/login" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <HashRouter>
        {routes}
        {/* <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch> */}
      </HashRouter>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isAuthenticated: store.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignUp: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  reviews: null,
  ratings: null,
};

const fetchReviews = (state, action) => {
  return updateObject(state, { reviews: action.reviews });
};

const fetchRatings = (state, action) => {
  return updateObject(state, { ratings: action.ratings });
};

const reviews = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REVIEWS:
      return fetchReviews(state, action);
    case actionTypes.FETCH_RATINGS:
      return fetchRatings(state, action);
    default:
      return state;
  }
};

export default reviews;

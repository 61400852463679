import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

const fetchSubId = (subscriptionId) => {
  return {
    type: actionTypes.PAYMENT_SUB_ID,
    subscriptionId,
  };
};

export const fetchSubscription = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;

    axios({
      method: 'GET',
      url: '/api/v1/user/getSubscriptionId',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (
        typeof response.data === 'string' ||
        response.data instanceof String
      ) {
        dispatch(fetchSubId(response.data));
      } else {
        dispatch(fetchSubId(false));
      }
    });
  };
};

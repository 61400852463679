export const updateObject = (oldObject, updatedValues) => {
  return {
    ...oldObject,
    ...updatedValues,
  };
};

export const roles = {
  free: 'ROLE_USER',
  plus: 'ROLE_PLUS',
  pro: 'ROLE_PRO',
  ultimate: 'ROLE_ULTIMATE',
};

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  userId: '',
  whiteList: [],
  brand: '',
  userRole: [],
  subscriptionId: null,
  userSettings: {
    user_id: '',
    hashed_user_id: '',
    username: '',
    email: '',
    brand: '',
    domain_list: [],
    user_plan: [],
    tid: '',
  },
};

const fetchUserId = (state, action) => {
  return updateObject(state, { userId: action.userId });
};

const fetchWhiteList = (state, action) => {
  return updateObject(state, { whiteList: action.whiteList });
};

const fetchBrand = (state, action) => {
  return updateObject(state, { brand: action.brand });
};

const fetchUserRole = (state, action) => {
  return updateObject(state, { userRole: action.userRole });
};

const fetchAllSettings = (state, action) => {
  return {
    ...state,
    userSettings: {
      ...state.userSettings,
      user_id: action.userSettings.user_id,
      hashed_user_id: action.userSettings.hashed_user_id,
      username: action.userSettings.username,
      email: action.userSettings.email,
      brand: action.userSettings.brand,
      domain_list: action.userSettings.domain_list,
      user_plan: action.userSettings.user_plan,
      reviews_limit: action.userSettings.reviews_limit,
      appsumo_plan_id: action.userSettings.appsumo_plan_id,
      appsumo_invoice_item_uuid: action.userSettings.appsumo_invoice_item_uuid,
      tid: action.userSettings.tid,
    },
  };
};

const clearUserData = (state) => {
  return updateObject(state, {
    user_id: '',
    whiteList: [],
    brand: '',
    userRole: [],
    subscriptionId: null,
    userSettings: {
      userId: '',
      hashed_user_id: '',
      username: '',
      email: '',
      brand: '',
      domain_list: [],
      user_plan: [],
      reviews_limit: '',
      appsumo_plan_id: '',
      appsumo_invoice_item_uuid: '',
      tid: '',
    },
  });
};

const userData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_SETTINGS:
      return fetchAllSettings(state, action);
    case actionTypes.FETCH_USERID:
      return fetchUserId(state, action);
    case actionTypes.FETCH_WHITELIST:
      return fetchWhiteList(state, action);
    case actionTypes.FETCH_BRAND:
      return fetchBrand(state, action);
    case actionTypes.FETCH_ROLE:
      return fetchUserRole(state, action);
    case actionTypes.AUTH_LOGOUT:
      return clearUserData(state);
    default:
      return state;
  }
};

export default userData;

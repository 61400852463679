import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  subscriptionId: null,
};

const fetchSubId = (state, action) => {
  return updateObject(state, { subscriptionId: action.subscriptionId });
};

const subscriptionId = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT_SUB_ID:
      return fetchSubId(state, action);
    default:
      return state;
  }
};

export default subscriptionId;
